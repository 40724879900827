<template>
 <div class="home">
    <div v-show="show && showEvent($route.meta?.hiddenBack)">
      <NavBar/>
    </div>
    <router-view v-slot="{Component}">
      <keep-alive>
        <component :is="Component" :key="$route.name"  v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <component :is="Component"  :key="$route.name" v-if="!$route.meta.keepAlive"/>
    </router-view>
 </div>
</template>

<script>
import { onMounted, provide, reactive, toRefs } from '@vue/runtime-core'
import { loginData, chooseNumber } from './store'
import { refreshData } from './utils/util'
export default {
  name: 'App',
  setup() {
    provide('loginStatus', loginData()),
    provide('chooseNumberInfo', chooseNumber())
    refreshData()
    const state = reactive({
      show: false
    })
    onMounted(() => {
      setTimeout(() => {
                state.show = true
            }, 1000)
    })
    function showEvent(hidden) {
        console.log(hidden)
        return hidden ? false : true
    }
    return {
      ...toRefs(state),
      showEvent
    }
  }
}
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
}
.home_content {
  padding-bottom: 40px;
}
</style>
