import ssqIcon from '../assets/imgs/home_icon_ssq.png'
import sixplusoneIcon from '../assets/imgs/home_icon_6and1.png'
import fifteenIcon from '../assets/imgs/home_icon_15pick5.png'
import cx4Icon from '../assets/imgs/home_icon_cx4.png'
import fc3dIcon from '../assets/imgs/home_icon_fc3d.png'
import sevenIcon from '../assets/imgs/home_icon_7lc.png'
// import openAccountIcon from '../assets/imgs/open-account-order.png'
import otherOrderIcon from '../assets/imgs/other-order.png'
// import kl8 from '../assets/imgs/home_icon_kl8.png'

export const lotterys = [
    {
        url: ssqIcon, 
        name: '双色球', 
        tip: '2元赢取1000万', 
        hot: true, 
        flag: '今日开奖', 
        bonusDay: [2, 4, 0],
        drawTime: '21:15',
        id: 11,
        // qcId: 1,
        code: 'SSQ',
        hasTrendData: true,
        bonusTime: '每周二、四、日开奖',
        charityRate: 0.36,
        yearPeriods: 150
    },
    {
        url: fc3dIcon, 
        name: '福彩3D', 
        tip: '固定奖金,日日开', 
        hot: true, 
        qcId: 3,
        flag: '', 
        drawTime: '21:15',
        id: 51,
        code: 'D3',
        hasTrendData: true,
        bonusTime: '每天开奖',
        charityRate: 0.36,
        yearPeriods: 365
    },
    {
        url: sevenIcon, 
        name: '七乐彩', 
        tip: '百万奖,期期开', 
        hot: false,
        bonusDay: [1, 3, 5],
        drawTime: '21:15',
        flag: '', 
        id: 24,
        qcId: 4,
        code: 'QLC',
        hasTrendData: true,
        bonusTime: '每周一、三、五开奖',
        charityRate: 0.36,
        yearPeriods: 150
    },
    {
        url: sixplusoneIcon, 
        name: '东方6+1', 
        tip: '幸运生肖赢大奖', 
        hot: false, 
        bonusDay: [1, 3, 6],
        drawTime: '19:00',
        flag: '', 
        id: 61,
        code: 'DF61',
        hasTrendData: true,
        bonusTime: '每周一、三、六开奖',
        charityRate: 0.34,
        yearPeriods: 150
    },
    {
        url: cx4Icon, 
        name: '天天彩选4', 
        tip: '猜中四位赢万元', 
        hot: false, 
        drawTime: '20:30',
        flag: '', 
        id: 52,
        code: 'D4',
        bonusTime: '每天开奖',
        charityRate: 0.34,
        yearPeriods: 365
    },
    {
        url: fifteenIcon, 
        name: '15选5', 
        tip: '最高可中500万', 
        hot: false, 
        drawTime: '19:00',
        flag: '', 
        id: 23,
        code: 'G15x5',
        bonusTime: '每天开奖',
        charityRate: 0.34,
        yearPeriods: 365
    },
    // {url: kl8, name: '快乐8', tip: '单注可中500万', hot: false, flag: ''},
]

// 通过ID获取彩种信息
export const gameIDMap = new Map()
lotterys.forEach(ele => {
    if (ele.id) {
        gameIDMap.set(ele.id, {icon: ele.url, ...ele})
    }
})

// 通过彩种名获取彩种信息
export const lotteryNameMap = new Map()
lotterys.forEach(ele => {
    if (ele.name) {
        lotteryNameMap.set(ele.name, {id: ele.id, name: ele.name, icon: ele.url})
    }
})

export const ssqGameId = lotteryNameMap.get('双色球')&&lotteryNameMap.get('双色球').id

// single: 单式 multiply：复式 sumValue： 和值
// 双色球多期数据
const ssqInfoMachine =  [
    {
        label: '双色球机选多期',
        type: 'single',
        orderNumber: 'a33611'
    },
]
// 3D多期数据
const fc3DInfoMachine = [
    {
        label: '直选',
        type: 'single',
        orderNumber: 'a0311'
    },
    {
        label: '组选',
        type: 'single',
        orderNumber: 'a0331'
    }
]
// 彩选4
const cx4Machine = [
    {
        label: '直选',
        type: 'single',
        orderNumber: 'a04111'
    },
    {
        label: '组选',
        type: 'single',
        orderNumber: 'a04131'
    }
]
// 七乐彩多期数据
const sevenLcMachine = [
    {
        label: '七乐彩机选多期',
        type: 'single',
        orderNumber: 'a3011'
    },
]
// 15选5
const fifteenChooseFiveMachine = [
    {
        label: '15选5机选多期',
        type: 'single',
        orderNumber: 'a1511'
    },
]
// 6+1
const sixPlusOneMachine = [
    {
        label: '东方6+1机选多期',
        type: 'single',
        orderNumber: 'a0611'
    },
]

// 机选数据列表
export const machineChooseList = [
    {
        id: 11, // 双色球
        modes: [
            {
                label: '单式机选',
                orderNumber: 'a3361',
            }
        ]
    },
    {
        id: 51, // 3D
        modes: [
            {
                label: '通选机选',
                orderNumber: 'a0361',
                multiTimes: true,
            },
            {
                label: '包选机选',
                orderNumber: 'a0368',
                multiTimes: true,
            },
            {
                label: '单式机选',
                orderNumber: 'a031',
            },
            {
                label: '组选机选',
                orderNumber: 'a033',
            },
        ]
    },
    {
        id: 24, // 七乐彩
        modes: [
            {
                label: '单式机选',
                orderNumber: 'a301',
            }
        ]
    },
    {
        id: 61, // 东方6+1
        modes: [
            {
                label: '单式机选',
                orderNumber: 'a061',
            }
        ]
    },
    {
        id: 52, // 天天彩选4
        modes: [
            {
                label: '直选机选',
                orderNumber: 'a0411',
            },
            {
                label: '组选机选',
                orderNumber: 'a0413',
            },
        ]
    },
    {
        id: 23, // 15选5
        modes: [
            {
                label: '单式机选',
                orderNumber: 'a151',
            }
        ]
    }
]

// 双色球
const ssqInfo = {
    modeIndex: 0,
    modes: [
        {
            label: '普通选号',
            type: 'multiply',
            multiPours: [true, false],
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: ['a3366', 'a33632'],
            typeTexts: ['单式', '复式'],
            split: [false, true],
            showBalls: {},
            omitData: [], // 遗漏数据
            omitTypeValue: '3000',
            genPourNumberName: 'ssqSingle',
            startNum: 1,
            multiperiodOrder: 'a3362TC',
            autoLotteryNumber: ['a33621', 'a33631'],
            singleTip: '请选择6个<span class="red">红球</span>和1个<span class="blue">蓝球</span>',
            tip: '至少选择6个<span class="red">红球</span>和1个<span class="blue">蓝球</span>',
            balls: [
                {type: 'single', min: 6, max: 12, numberType: true, length: 33, color: 'red'},
                {type: 'single', min: 1, max: 16, numberType: true, length: 16, color: 'blue'}
            ]
        },
        {
            label: '胆拖选号',
            orderNumber: 'a3364',
            type: 'courageDrag',
            machineChoose: false,
            betNumberArr: [],
            multiPours: false,
            multiTimes: true,
            omitTypeValue: '3002',
            // autoLotteryNumber: 'a33631',
            chooseNum: 7, 
            startNum: 1,
            tips: '红球胆码区+拖码区，选球个数最少7个球, 蓝球至少一个',
            rule: '提示：红球胆码区+拖码区，选球个数最少7个球。',
            split:true,
            balls: [
                {label: '胆码区，红球，最少选择1个，最多选择5个', mutexRow: 1, type: 'compound', min: 1, max: 5, numberType: true, length: 33, color: 'red'},
                {label: '拖码区，红球，至少选择2个', type: 'times', mutexRow: 0, prop: 'length', min: 2, max: 16, numberType: true, length: 33, color: 'red'},
                {label: '蓝码区，蓝球，至少选择1个', type: 'times', prop: 'length', min: 1, max: 16, numberType: true, length: 16, color: 'blue'}
            ]
        }
    ]
}

// // 选号数据模板
// const chooseTemplate = {
//     modeIndex: 0,
//     modes: [
//         {
//             label: '',
//             type: '',
//             multiPours: false,
//             multiTimes: false,
//             machineChoose: true,
//             showBallsIndex: -1,
//             orderNumber: '',
//             startNum: 1,
//             betNumberArr: [{redBalls: [8, 11, 16, 32, 44, 48], blueBalls: [0, 1, 2, 4]}],
//             balls: [
//                 {label: '请选择6个红球', type: 'single', min: 6, max: 6, numberType: true, length: 33, color: 'red'},
//             ]
//         }
//     ]
// }
// 东方6+1
const sixPlusOne = {
    modeIndex: 0,
    modes: [
        {
            label: '单式',
            type: 'single',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: 'a0624',
            autoLotteryNumber: 'a0621',
            multiperiodOrder: 'a062TC',
            omitTypeValue: '4000',
            startNum: 0,
            split: true,
            tip: '基本号码至少选择6个',
            balls: [
                {label: '请选择1个基本号码', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '请选择1个基本号码', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '请选择1个基本号码', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '请选择1个基本号码', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '请选择1个基本号码', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '请选择1个基本号码', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '请选择生肖', type: 'single', min: 1, max: 1, numberType: false, length: 10, color: 'blue', labels: ['鼠','牛','虎','兔','龙','蛇','马','羊','猴','鸡','狗','猪']},
            ]
        }
    ]
}

// 天天彩选4
const cx4 = {
    modeIndex: 0,
    modes: [
        {
            label: '直选-单式',
            type: 'single',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: 'a04122',
            autoLotteryNumber: 'a04121',
            multiperiodOrder: 'a0412TC',
            startNum: 0,
            tip: '每位至少选择一个号码',
            rule: '投注号码与开奖号码按位相符，即中奖。',
            omitTypeValue: '6000',
            balls: [
                {label: '千位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '百位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '十位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '个位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
            ]
        },
        {
            label: '组选4-单式',
            type: 'cx4Group',
            modeIndex: 1,
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: 'a04142',
            autoLotteryNumber: 'a04141',
            autoLotteryMultiTimes: false,
            startNum: 0,
            rule: '投注号码与开奖号码相同，顺序不限，即中奖。',
            balls: [
                {label: '重号', mutexRow: 1, type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '个位', mutexRow: 0, type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
            ]
        },
        {
            label: '组选6-单式',
            type: 'cx4Group',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            modeIndex: 2,
            orderNumber: 'a04142',
            autoLotteryNumber: 'a04141',
            autoLotteryMultiTimes: false,
            startNum: 0,
            rule: '投注号码与开奖号码相同，顺序不限，即中奖。',
            balls: [
                {label: '重号', mutexRow: 1, type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '重号', mutexRow: 0,type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
            ]
        },
        {
            label: '组选12-单式',
            type: 'cx4Group',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            modeIndex: 3,
            orderNumber: 'a04142',
            autoLotteryNumber: 'a04141',
            autoLotteryMultiTimes: false,
            startNum: 0,
            rule: '投注号码与开奖号码相同，顺序不限，即中奖。',
            balls: [
                {label: '重号', mutexRow: [1, 2], type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '十位', mutexRow: [0, 2], type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '个位', mutexRow: [0, 1],type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
            ]
        },
        {
            label: '组选24-单式',
            type: 'cx4Group',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            modeIndex: 4,
            orderNumber: 'a04142',
            autoLotteryNumber: 'a04141',
            autoLotteryMultiTimes: false,
            startNum: 0,
            rule: '投注号码与开奖号码相同，顺序不限，即中奖。',
            balls: [
                {label: '请选择4个号码', type: 'single', min: 4, max: 4, numberType: true, length: 10, color: 'red'},
            ]
        }
    ]
}

// 15选5
const fifteenChooseFive = {
    modeIndex: 0,
    modes: [
        {
            label: '普通选号',
            typeTexts: ['单式', '复式'],
            type: 'multiply',
            multiPours: [true, false],
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: ['a1524', 'a1532'],
            autoLotteryNumber: ['a1521', 'a1531'],
            multiperiodOrder: 'a152TC',
            startNum: 1,
            chooseNum: 5,
            omitTypeValue: '7000',
            singleTip: '请选择5个号码',
            tip: '请选择5-12个号码',
            balls: [
                {type: 'single',multiMin: 6, min: 5, max: 12, numberType: true, length: 15, color: 'red'},
            ]
        },
    ]
}

// 七乐彩
const sevenLc = {
    modeIndex: 0,
    modes: [
        {
            label: '普通选号',
            typeTexts: ['单式', '复式'],
            type: 'multiply',
            multiPours: [true, false],
            multiTimes: [true, false],
            multiperiodOrder: 'a308TC',
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: ['a304', 'a303'],
            autoLotteryNumber: ['a3021', 'a3031'],
            startNum: 1,
            omitTypeValue: '1000',
            singleTip: '请选择7个号码',
            tip: '请选择7-12个号码',
            balls: [
                { type: 'single',multiMin: 8, min: 7, max: 12, numberType: true, length: 30, color: 'red'},
            ]
        },
    ]
}

// 福彩3D
const fc3D = {
    modeIndex: 0,
    modes: [
        {  // 0
            label: '直选-单式',
            type: 'single',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: 'a0322',
            autoLotteryNumber: 'a0321',
            multiperiodOrder: 'a032TC',
            tip: '每位至少选择一个号码',
            rule: '投注号码与当期开奖号码按位全部相同（百位+十位+个位），即中奖。',
            // label: '每位选择一个号码',
            startNum: 0,
            omitTypeValue: '2000',
            balls: [
                {label: '百位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '十位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '个位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
            ]
        },
        { // 1
            label: '组选3-单式',
            type: 'zx3DGroup3',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            genPourNumberName: 'fc3Dzx3',
            orderNumber: 'a0342',
            autoLotteryNumber: 'a0341',
            startNum: 0,
            rule: '当期开奖号码的三位数中任意两位数字相同，且投注号码与当期开奖号码相同（顺序不限），即中奖。',
            balls: [
                {label: '请选择两个号码', type: 'multiply', min: 2, max: 2, numberType: true, length: 10, color: 'red'},
            ]
        },
        { // 2
            label: '组选6-单式',
            type: 'single',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: 'a0342',
            autoLotteryNumber: 'a0341',
            startNum: 0,
            rule: '当期开奖号码的三位数各不相同，且投注号码与当期开奖号码相同（顺序不限），即中奖。',
            balls: [
                {label: '请选择3个号码', type: 'single', min: 3, max: 3, numberType: true, length: 10, color: 'red'},
            ]
        },
        { // 3
            label: '直选-和值',
            type: 'zxhz',
            multiPours: false,
            multiTimes: false,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: 'a036',
            startNum: 0,
            omitTypeValue: '2240',
            rule: '投注号码与当期开奖号相加之和相同，且符合直选玩法规则，即中奖。',
            balls: [
                {label: '请选择1个号码', type: 'single', min: 1, max: 1, numberType: true, length: 28, color: 'red'},
            ]
        },
         // 新3D玩法
         { // 4
            label: '通选',
            type: 'single',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            timesNeeded: true,
            showBallsIndex: -1,
            orderNumber: 'a0362',
            // autoLotteryNumber: 'a0361',
            tip: '每位至少选择一个号码',
            rule: '<span> 通选1：投注号码与当期开奖号码按位全部相同（百位+十位+个位），即中奖；</span><span>通选2：投注号码与当期开奖号码中任意两个位置的号码按位相同，即中奖。</span>',
            // label: '每位选择一个号码',
            startNum: 0,
            omitTypeValue: '2000',
            balls: [
                {label: '百位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '十位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '个位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
            ]
        },
        { // 5
            label: '大小',
            type: 'single',
            multiPours: false,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            timesNeeded: true,
            orderNumber: 'a0363',
            // autoLotteryNumber: 'a0361',
            // tip: '请选择大小',
            rule: '投注号码与当期开奖号码的三个号码相加之和的大、小性质相同，即中奖。其中，三个号码相加之和在19（含）至27（含）之间时为大，在0（含）至8（含）之间时为小。',
            // label: '每位选择一个号码',
            startNum: 0,
            balls: [
                {label: '请选择大小', type: 'single', min: 1, max: 1, numberType: false, length: 2, color: 'red', labels: ['大', '小']},
            ]
        },
        { // 6
            label: '奇偶',
            type: 'single',
            multiPours: false,
            multiTimes: true,
            machineChoose: true,
            timesNeeded: true,
            showBallsIndex: -1,
            orderNumber: 'a0364',
            // autoLotteryNumber: 'a0361',
            // tip: '请选择奇偶',
            rule: '当期开奖号码的三个号码全部为奇数或偶数，且投注号码与当期开奖号码的三个号码的奇数、偶数性质相同，即中奖。其中，1、3、5、7、9为奇，0、2、4、6、8为偶。',
            // label: '每位选择一个号码',
            startNum: 0,
            balls: [
                {label: '请选择奇偶', type: 'single', min: 1, max: 1, numberType: false, length: 2, color: 'red', labels: ['奇', '偶']},
            ]
        },
        { // 7
            label: '1D单式',
            type: '1D',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            timesNeeded: true,
            showBallsIndex: -1,
            orderNumber: 'a0365',
            machineChooseLen: 1,
            // autoLotteryNumber: 'a0361',
            tip: '请至少选择一个号码',
            rule: '<span>猜中1：投注号码与当期开奖号码中任意一个位置的号码相同，即中奖；</span><span>猜中2：投注号码与当期开奖号码中任意两个位置的号码相同，即中奖；</span><span>猜中3：投注号码与当期开奖号码中全部三个位置的号码相同，即中奖。</span>',
            // label: '每位选择一个号码',
            startNum: 0,
            omitTypeValue: '2000',
            balls: [
                {label: '百位', type: 'single', min: 1, max: 10, numberType: true, length: 10, color: 'red'},
                {label: '十位', type: 'single', min: 1, max: 10, numberType: true, length: 10, color: 'red'},
                {label: '个位', type: 'single', min: 1, max: 10, numberType: true, length: 10, color: 'red'},
            ]
        },
        { // 8
            label: '2D单式',
            type: '2D',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            timesNeeded: true,
            showBallsIndex: -1,
            orderNumber: 'a0366',
            machineChooseLen: 2,
            // autoLotteryNumber: 'a0361',
            tip: '请至少选择两个号码',
            rule: '投注号码与当期开奖号码中对应两个位置的号码按位相同，即中奖。',
            // label: '每位选择一个号码',
            startNum: 0,
            omitTypeValue: '2000',
            balls: [
                {label: '百位', type: 'single', min: 1, max: 10, numberType: true, length: 10, color: 'red'},
                {label: '十位', type: 'single', min: 1, max: 10, numberType: true, length: 10, color: 'red'},
                {label: '个位', type: 'single', min: 1, max: 10, numberType: true, length: 10, color: 'red'},
            ]
        },
        { // 9
            label: '和数单式',
            type: 'single',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            timesNeeded: true,
            showBallsIndex: -1,
            orderNumber: 'a0367',
            // autoLotteryNumber: 'a0361',
            tip: '请至少选择一个号码',
            rule: '投注号码与当期开奖号码的三个号码相加之和相同，即中奖。',
            // label: '每位选择一个号码',
            startNum: 0,
            balls: [
                {label: '百位', type: 'single', min: 1, max: 1, numberType: true, length: 28, color: 'red'},
            ]
        },
        { // 10
            label: '包选三-单式',
            type: 'bx3',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            timesNeeded: true,
            orderNumber: 'a0369',
            repeat: 2,
            // autoLotteryNumber: 'a0368',
            tip: '每位选择一个号码',
            rule: '<span>全中：投注号码的三位数中任意两位数字相同，且投注号码与当期开奖号码按位全部相同，即中奖；</span><span>组中：投注号码的三位数中任意两位数字相同，且投注号码与当期开奖号码全部相同（顺序不同），即中奖。</span>',
            // label: '每位选择一个号码',
            startNum: 0,
            omitTypeValue: '2000',
            balls: [
                {label: '百位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '十位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '个位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
            ]
        },
        { // 11
            label: '包选六-单式',
            type: 'single',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            timesNeeded: true,
            repeat: 1,
            orderNumber: 'a0369',
            // autoLotteryNumber: 'a0368',
            tip: '每位选择一个号码，所选号码不能重复',
            rule: '<span>全中：投注号码的三位数各不相同，且投注号码与当期开奖号码按位全部相同，即中奖；</span><span>组中：投注号码的三位数各不相同，且投注号码与当期开奖号码全部相同（顺序不同），即中奖。</span>',
            // label: '每位选择一个号码',
            startNum: 0,
            omitTypeValue: '2000',
            balls: [
                {label: '百位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '十位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '个位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
            ]
        },
        { // 12
            label: '猜三同',
            type: 'single',
            multiPours: false,
            multiTimes: true,
            machineChoose: true,
            timesNeeded: true,
            showBallsIndex: -1,
            orderNumber: 'a0370',
            // autoLotteryNumber: 'a0368',
            tip: '每位至少选择一个号码',
            rule: '当期开奖号码为三个相同的号码，即中奖。',
            // label: '每位选择一个号码',
            startNum: 0,
            balls: [
                {label: '同号', type: 'single', min: 1, max: 1, numberType: true, length: 1, color: 'red'},
            ],
            chooseAll: ['000','111','222','333','444','555','666','777','888','999']
        },
        { // 13
            label: '拖拉机',
            type: 'single',
            multiPours: false,
            multiTimes: true,
            machineChoose: true,
            timesNeeded: true,
            showBallsIndex: -1,
            orderNumber: 'a0371',
            // autoLotteryNumber: 'a0368',
            tip: '每位至少选择一个号码',
            rule: '当期开奖号码的三个号码为以升序或降序连续排列的号码（890、098、901、109除外），即中奖。',
            // label: '每位选择一个号码',
            startNum: 0,
            balls: [
                {label: '同号', type: 'single', min: 1, max: 1, numberType: true, length: 1, color: 'red'},
            ],
            chooseAll: ['012', '123', '234', '345', '456', '567', '678', '789', '210', '321', '432', '543', '654', '765', '876', '987'],
        },
    ]
}

export const auotLotteryNotTimes = [
    'a04141'
]

export const chooseNumberArr = new Map()
chooseNumberArr.set(11, ssqInfo)
chooseNumberArr.set(51, fc3D)
chooseNumberArr.set(24, sevenLc)
chooseNumberArr.set(61, sixPlusOne)
chooseNumberArr.set(52, cx4)
chooseNumberArr.set(23, fifteenChooseFive)

export const chooseNumberArrMachine = new Map()
chooseNumberArrMachine.set(11, ssqInfoMachine)
chooseNumberArrMachine.set(51, fc3DInfoMachine)
chooseNumberArrMachine.set(24, sevenLcMachine)
chooseNumberArrMachine.set(61, sixPlusOneMachine)
chooseNumberArrMachine.set(52, cx4Machine)
chooseNumberArrMachine.set(23, fifteenChooseFiveMachine)



export const messageOrder = [
    {
        name: '双色球多期',
        title: '双色球多期短信指令',
        icon: ssqIcon,
        tip: '多期，自在无忧',
        orders: [
               {
                name:'机选包周 ',
                order: 'a3361TC01',
                example:'例：机选3期，指令为：a3361TC01 ',
                },
                {
                name:'机选包月 ',
                order:'a3361TC02',
                // tips:'a3361TC02',
                example:'例：机选13期，指令为：a3361TC02 ',
                },
                {
                name:'机选包季 ',
                order:'a3361TC03',
                // tips:'a3361TC03',
                example:'例：机选39期，指令为：a3361TC03 ',
                },
                {
                name:'机选包半年 ',
                order:'a3361TC04',
                // tips:'a3361TC04',
                example:'例：机选78期，指令为：a3361TC04 ',
                },
                {
                name:'机选包年 ',
                order:'a3361TC05',
                // tips:'a3361TC05',
                example:'例：机选153期，指令为：a3361TC05 ',
                },
                {
                name:'自选包周 ',
                order: 'a3362TC01',
                example:'例：自选3期，指令为：a3362TC01#01020304050607 ',
                },
                {
                name:'自选包月 ',
                order:'a3362TC02#单式号码',
                // tips:'a3362TC02#单式号码',
                example:'例：自选13期，指令为：a3362TC02#01020304050607 ',
                },
                {
                name:'自选包季 ',
                order:'a3362TC03#单式号码',
                // tips:'a3362TC03#单式号码',
                example:'例：自选39期，指令为：a3362TC03#01020304050607 ',
                },
                {
                name:'自选包半年 ',
                order:'a3362TC04#单式号码',
                // tips:'a3362TC04#单式号码',
                example:'例：自选78期，指令为：a3362TC04#01020304050607 ',
                },
                {
                name:'自选包年 ',
                order:'a3362TC05#单式号码',
                // tips:'a3362TC05#单式号码',
                example:'例：自选153期，指令为：a3362TC05#01020304050607 ',
                },
        ]
    },
    {
        name: '双色球',
        title: '双色球短信指令',
        icon: ssqIcon,
        tip: '2元能中1000万',
        orders: [
            {
                name: '单式机选',
                order: 'a3361#机选注数',
                example: '机选3注，指令为：a3361#3'
            },
            {
                name: '单式自选',
                order: 'a3362#单式号码1#单式号码2…',
                tips: '红球和蓝球间没有间隔，最后一个号码默认为蓝球。一条短信可以自选1－5注号码。',
                example: '自选2注号码，指令为：a3362#01020304050607#28293031323301'
            },
            {
                name: '单式自选倍投',
                order: 'a33622#单式号码#倍数',
                tips: '红球和蓝球间没有间隔，最后一个号码默认为蓝球。一条短信仅能倍投1注号码。',
                example: '自选1注号码，购买2倍，指令为：a33622#01020304050607#2'
            },
            {
                name:'复式 ',
                order:'a3363#红球号码#蓝球号码',
                tips:'（可自选7－12个红球和1－16个蓝球。一条短信仅能自选1注复式号码。）',
                example:'例：自选1注复式号码，指令为： a3363#0102030405060708#070809 ',
            },
            {
                name:'复式倍投 ',
                order:'a33632#红球号码#蓝球号码#倍数',
                tips:'（可自选7－12个红球和1－16个蓝球。一条短信仅能自选1注复式号码。）',
                example:'例：自选1注复式号码，购买2倍，指令为：a33632#01020304050607#0708#2 ',
            },
            {
                name:'机选自动 ',
                order:'a33611#机选注数',
                tips:'（系统每期自动为用户购买相应注数的机选号码，直到卡内资金不足或用户取消自动为止。自动投注指令下期生效。）',
                example:'例：设定每期机选4注，指令为：a33611#4 ',
            },
            {
                name:'单式自选自动 ',
                order:'a33621#单式号码#倍数（指令不加#倍数则默认为1倍）',
                tips:'（红球和蓝球间没有间隔，最后一个号码默认为蓝球。系统每期自动为用户购买该自选号码，直到卡内资金不足或用户 取消自动为止。一条短信仅能设定1注号码。自动投注指令下期生效。）',
                example:'例：设定每期购买1注单式自选号码，且购买3倍，指令为：a33621#01020304050607#3 ',
            },
            {
                name:'复式自选自动 ',
                order:'a33631#红球号码#蓝球号码#倍数（指令不加#倍数则默认为1倍）',
                tips:'（可自选7－12个红球和1－16个蓝球。系统每期自动为用户购买该自选号码，直到卡内资金不足或用户取消自动为止。 一条短信仅能设定1注号码。自动投注指令下期生效。）',
                example:'例：设定每期购买1注复式自选号码，且购买3倍，指令为：a33631#01020304050607#101112#3',
            },
            {
                name:'取消自动 ',
                order: 'a3365',
                example:'如已设定多条自动投注，发送取消指令后，所有双色球的自动投注全部取消。取消指令下期生效。 ',
            },
        ]
    },
    {
        name: '福彩3D',
        title: '3D短信指令',
        icon: fc3dIcon,
        tip: '固定奖，天天开',
        orders: [
            {
                name:'通选机选',
                order: 'a0361#机选注数#倍数(倍投不能省略)',
                example:'例：通选机选3注1倍号码，指令为：a0361#3#1 ',
                },
                {
                name:'通选自选',
                order:'a0362#号码1#号码2#号码3…#倍数(倍投不能省略)',
                tips:'（一条短信可以自选1－5注号码）',
                example:'例：通选自选2注1倍号码，指令为：a0362#125#346#1 ',
                },
                {
                name:'猜大小 ',
                order:'a0363#号码#倍数(倍投不能省略)',
                tips:'（一条短信只能自选1注号码，号码 1：小 2：大）',
                example:'例：猜1注当期开奖号码之和是小的1倍号码，指令为：a0363#1#1 ',
                },
                {
                name:'猜奇偶 ',
                order:'a0364#号码#倍数(倍投不能省略)',
                tips:'（一条短信只能自选1注号码，号码 4：偶 5：奇）',
                example:'例：猜1注当期开奖号码是偶数的1倍号码，指令为：a0364#4#1 ',
                },
                {
                name:'1D单式 ',
                order:'a0365#号码1#号码2#号码3…#倍数(倍投不能省略)',
                tips:'（一条短信可以自选1－5注号码,每个号码3位，不选部分用a代替）',
                example:'例：猜2注分别是十位数为1，百位数为5的1倍号码，指令为：a0365#a1a#5aa#1',
                },
                {
                name:'2D单式 ',
                order:'a0366#号码1#号码2#号码3…#倍数(倍投不能省略)',
                tips:'（一条短信可以自选1－5注号码，每个号码3位，不选部分用a代替）',
                example:'例：猜2注十位个位分别为1和2，百位个位分别为5和7的1倍号码，指令为：a0366#a12#5a7#1',
                },
                {
                name:'和数单式 ',
                order:'a0367#号码1#号码2#号码3…#倍数(倍投不能省略)',
                tips:'（一条短信可以自选1－5注号码，号码为0~27中的任意一个）',
                example:'例：自选2注和值分别是6和24的1倍号码，指令为：a0367#6#24#1',
                },
                {
                name:'包选机选 ',
                order: 'a0368#机选注数#倍数(倍投不能省略)',
                example:'例：包选机选4注1倍号码，指令为：a0368#4#1 ',
                },
                {
                name:'包选自选 ',
                order: 'a0369#号码1#号码2#号码3…#倍数(倍投不能省略)',
                example:'例：包选自选2注1倍号码，指令为：a0369#345#278#1 ',
                },
                {
                name:'猜三同 ',
                order:'a0370#号码#倍数(倍投不能省略)',
                tips:'（一条短信仅支持一注号码，固定为aaa）',
                example:'例：投注号码固定为aaa，指令为：a0370#aaa#1 ',
                },
                {
                name:'拖拉机 ',
                order:'a0371#号码#倍数(倍投不能省略)',
                tips:'（一条短信仅支持一注号码，固定为abc）',
                example:'例：投注号码固定为abc，指令为：a0371#abc#1 ',
                },
                {
                name:'单式机选 ',
                order: 'a031#机选注数',
                example:'例：机选3注直选号码，指令为：a031#3 ',
                },
                {
                name:'组选机选 ',
                order:'a033#机选注数',
                tips:'a033#机选注数',
                example:'例：机选3注组选号码，指令为：a033#3 ',
                },
                {
                name:'直选单式 ',
                order:'a032#直选号码1#直选号码2…',
                tips:'（一条短信可以自选1－5注号码。）',
                example:'例：自选2注直选号码，指令为：a032#012#123 ',
                },
                {
                name:'组选单式 ',
                order:'a034#组选号码1#组选号码2…',
                tips:'（一条短信可以自选1－5注号码。）',
                example:'例：自选2注组选号码，指令为：a034#012#123 ',
                },
                {
                name:'直选单式倍投 ',
                order:'a038#直选号码#倍数',
                tips:'（一条短信仅能倍投1注号码。倍数上限为99倍。）',
                example:'例：自选1注直选号码，购买88倍，指令为：a038#012#88 ',
                },
                {
                name:'组选单式倍投 ',
                order:'a039#组选号码#倍数',
                tips:'（一条短信仅能倍投1注号码。倍数上限为99倍。）',
                example:'例：自选1注组选号码，购买77倍，指令为：a039#012#77 ',
                },
                {
                name:'直选和值 ',
                order:'a036#和值',
                tips:'（和值范围0－27。一条短信仅能选择1个和值。）',
                example:'例：购买和值为9的所有直选号码，指令为：a036#9 ',
                },
                {
                name:'直选机选自动 ',
                order:'a0311#机选注数',
                tips:'（系统每期自动为用户购买相应注数的机选号码，直到卡内资金不足或用户取消自动为止。自动投注指令下期生效。）',
                example:'例：设定每期机选4注直选号码，指令为：a0311#4 ',
                },
                {
                name:'组选机选自动 ',
                order:'a0331#机选注数',
                tips:'（系统每期自动为用户购买相应注数的机选号码，直到卡内资金不足或用户取消自动为止。自动投注指令下期生效。）',
                example:'例：设定每期机选4注组选号码，指令为：a0331#4 ',
                },
                {
                name:'直选自选自动 ',
                order:'a0321#直选号码#倍数（指令不加#倍数则默认为1倍）',
                tips:'（系统每期自动为用户购买该自选号码，直到卡内资金不足或用户取消自动为止。一条短信仅能设定1注号码。自动投注指令下期生效。）',
                example:'例：设定每期购买1注直选自选号码，且购买3倍，指令为：a0321#123#3 ',
                },
                {
                name:'组选自选自动 ',
                order:'a0341#组选号码#倍数（指令不加#倍数则默认为1倍）',
                tips:'（系统每期自动为用户购买该自选号码，直到卡内资金不足或用户取消自动为止。一条短信仅能设定1注号码。自动投注指令下期生效。）',
                example:'例：设定每期购买1注组选自选号码，且购买3倍，指令为：a0341#123#3 ',
                },
                {
                name:'取消自动 ',
                order: 'a035',
                example:'如已设定多条自动投注，发送取消指令后，所有3D的自动投注全部取消。取消指令下期生效。 ',
                },
        ]
    },
    {
        name: '七乐彩',
        title: '七乐彩短信指令',
        icon: sevenIcon,
        tip: '最高奖金500万',
        orders: [
                {
                name:'单式机选 ',
                order: 'a301#机选注数',
                example:'例：机选3注，指令为：a301#3 ',
                },
                {
                name:'单式自选 ',
                order:'a302#单式号码1#单式号码2…',
                tips:'（一条短信可以自选1－5注号码。）',
                example:'例：自选2注号码，指令为：a302#01020304050607#10111213141516',
                },
                {
                name:'单式自选倍投 ',
                order:'a306#单式号码#倍数',
                tips:'（一条短信仅能倍投1注号码。）',
                example:'例：自选1注号码，购买2倍，指令为：a306#01020304050607#2 ',
                },
                {
                name:'复式 ',
                order:'a303#复式号码',
                tips:'（可自选8－12个号码。一条短信仅能自选1注复式号码。）',
                example:'例：自选1注复式号码，指令为： a303#010203040506070809 ',
                },
                {
                name:'机选自动 ',
                order:'a3011#机选注数',
                tips:'（系统每期自动为用户购买相应注数的机选号码，直到卡内资金不足或用户取消自动为止。自动投注指令下期生效。）',
                example:'例：设定每期机选4注，指令为：a3011#4 ',
                },
                {
                name:'单式自选自动 ',
                order:'a3021#单式号码#倍数（指令不加#倍数则默认为1倍）',
                tips:'',
                example:'例：设定每期购买1注单式自选号码，且购买3倍，指令为：a3021#01020304050607#3',
                },
                {
                name:'复式自动 ',
                order:'a3031#复式号码#倍数（指令不加#倍数则默认为1倍）',
                tips:'（可自选8－12个号码。系统每期自动为用户购买该自选号码，直到卡内资金不足或用户取消自动为止。一条短信仅能设定1注号码。自动投注指令下期生效。）',
                example:'例：设定每期购买1注复式号码，且购买3倍，指令为：a3031#010203040506070809#3 ',
                },
                {
                    name:'取消自动 ',
                    order: 'a305',
                    example: '如已设定多条自动投注，发送取消指令后，所有七乐彩的自动投注全部取消。取消指令下期生效。'
                }
        ]
    },
    {
        name: '15选5',
        title: '15选5短信指令',
        icon: fifteenIcon,
        tip: '浮动奖金，天天开',
        orders: [
                {
                name:'单式机选 ',
                order: 'a151#机选注数',
                example:'例：机选3注，指令为：a151#3 ',
                },
                {
                name:'单式自选 ',
                order:'a152#单式号码1#单式号码2…',
                tips:'（一条短信可以自选1－5注号码。）',
                example:'例：自选2注号码，指令为：a152#0102030405#1112131415 ',
                },
                {
                name:'单式自选倍投 ',
                order:'a1522#单式号码#倍数',
                tips:'（一条短信仅能倍投1注号码。）',
                example:'例：自选1注号码，购买2倍，指令为：a1522#0102030405#2 ',
                },
                {
                name:'复式 ',
                order:'a153#复式号码',
                tips:'（可自选6－12个号码。一条短信仅能自选1注复式号码。）',
                example:'例：自选1注复式号码，指令为：a153#0102030405060708 ',
                },
                {
                name:'复式倍投 ',
                order:'a1532#复式号码#倍数',
                tips:'（可自选6－12个号码。一条短信仅能自选1注复式号码 。 ）',
                example:'例：自选1注复式号码，购买2倍，指令为：a1532#0102030405060708#2 ',
                },
                {
                name:'机选自动 ',
                order:'a1511#机选注数',
                tips:'（系统每期自动为用户购买相应注数的机选号码，直到卡内资金不足或用户取消自动为止。自动投注指令下期生效。）',
                example:'例：设定每期机选4注，指令为：a1511#4 ',
                },
                {
                name:'单式自选自动 ',
                order:'a1521#单式号码#倍数（指令不加#倍数则默认为1倍）',
                tips:'（系统每期自动为用户购买该自选号码，直到卡内资金不足或用户取消自动为止。一条短信仅能设定1注号码。自动投注指令下期 生效。）',
                example:'例：设定每期购买1注单式自选号码，且购买3倍，指令为：a1521#0102030405#3 ',
                },
                {
                name:'复式自动 ',
                order:'a1531#复式号码#倍数（指令不加#倍数则默认为1倍）',
                tips:'（可自选6－12个号码。系统每期自动为用户购买该自选号码，直到卡内资金不足或用户取消自动为止。一条短信仅能设定1注号码。自动投注指令下期生效。）',
                example:'例：设定每期购买1注复式号码，且购买3倍，指令为：a1531#010203040506070809#3 ',
                },
                {
                    name:'取消自动 ',
                    order: 'a155',
                    example: '如已设定多条自动投注，发送取消指令后，所有15选5的自动投注全部取消。取消指令下期生效。'
                }
        ]
    },
    {
        name: '东方6+1',
        title: '东方6+1短信指令',
        icon: sixplusoneIcon,
        tip: '生肖+数字=大奖',
        orders: [
                {
                name:'单式机选 ',
                order: 'a061#机选注数',
                example:'例：机选3注，指令为：a061#3 ',
                },
                {
                name:'单式自选 ',
                order:'a062#基本码#生肖码…',
                tips:'（基本码和生肖码间以#隔开。一条短信可以自选1－5注号码。每注号码间以#隔开。）',
                example:'例：自选2注号码，指令为：a062#123456#01#654321#02 ',
                },
                {
                name:'自选倍投 ',
                order:'a0622#基本码#生肖码#倍数',
                tips:'（基本码和生肖码间以#隔开。一条短信仅能设定1注号码。）',
                example:'例：自选1注号码23倍，指令为：a0622#123456#12#23 ',
                },
                {
                name:'机选自动 ',
                order:'a0611#机选注数',
                tips:'（系统每期自动为用户购买相应注数的机选号码，直到卡内资金不足或用户取消自动为止。自动投注指令下期生效。）',
                example:'例：设定每期机选4注，指令为：a0611#4 ',
                },
                {
                name:'单式自动 ',
                order:'a0621#基本码#生肖码#倍数（指令不加#倍数则默认为1倍）',
                tips:'（系统每期自动为用户购买该自选号码，直到卡内资金不足或用户取消自动为止。一条短信仅能设定1注号码。自动投注指令下期 生效。）',
                example:'例：设定每期购买1注单式自选号码，并购买3倍，指令为：a0621#123456#05#3 ',
                },
                {
                    name:'取消自动 ',
                    order: 'a065',
                    example: '如已设定多条自动投注，发送取消指令后，所有东方6+1的自动投注全部取消。取消指令下期生效。'
                }
        ]
    },
    {
        name: '天天彩选4',
        title: '天天彩选4短信指令',
        tip: '简单四位赢万元',
        icon: cx4Icon,
        orders: [
                {
                name:'直选机选 ',
                order: 'a0411#机选注数',
                example:'例：机选3注直选号码，指令为：a0411#3',
                },
                {
                name:'直选自选 ',
                order:'a0412#自选号码',
                tips:'',
                example:'例：自选2注直选号码，指令为：a0412#0124#1236',
                },
                {
                name:'组选机选 ',
                order: 'a0413#机选注数',
                example:'例：机选3注组选号码，指令为：a0413#3',
                },
                {
                name:'组选自选 ',
                order:'a0414#自选号码',
                tips:'',
                example:'例：自选2注组选号码，指令为：a0414#0124#1236',
                },
                {
                name:'直选机选多期',
                order:'a04111#机选注数',
                tips:'（系统每期自动为用户购买相应注数的机选号码，直到卡内资金不足或用户取消自动为止。自动投注指令下期生效。）',
                example:'例：设定每期机选4注直选号码，指令为：a04111#4 ',
                },
                {
                name:'直选自选多期',
                order:'a04121#直选号码#倍数',
                tips:'（系统每期自动为用户购买该自选号码，直到卡内资金不足或用户取消自动为止。一条短信仅能设定1注号码。自动投注指令下期 生效。）',
                example:'例：设定每期购买1注直选自选号码，购买2倍。指令为：a04121#1234#2',
                },
                {
                name:'组选机选多期',
                order:'a04131#机选注数',
                tips:'（系统每期自动为用户购买相应注数的机选号码，直到卡内资金不足或用户取消自动为止。自动投注指令下期生效。）',
                example:'例：设定每期机选4注组选号码，指令为：a04131#4 ',
                },
                {
                name:'组选自选多期',
                order:'a04141#组选号码',
                tips:'（系统每期自动为用户购买该自选号码，直到卡内资金不足或用户取消自动为止。一条短信仅能设定1注号码。自动投注指令下期 生效。）',
                example:'例：设定每期购买1注组选自选号码，指令为：a04141#1233',
                },
                {
                    name:'取消自动 ',
                    order: 'a045',
                    example: '如已设定多条自动投注，发送取消指令后，所有天天彩选4的自动投注全部取消。取消指令下期生效。'
                }
        ]
    },
    {
        name: '其他指令',
        title: '其他指令',
        icon: otherOrderIcon,
        tip: '更多指令在这里',
        orders: [
                // {
                // name:'卡绑定',
                // order:'002#投注卡号或已开通投注功能的银行卡号#投注密码',
                // tips:'',
                // example:'绑定投注卡后，该手机与其他投注卡的绑定关系将解除。',
                // },
                // {
                // name:'解除绑定',
                // order:'003#投注卡号或已开通投注功能的银行卡号#投注密码',
                // tips:'',
                // example:'',
                // },
                {
                name:'投注卡转值',
                order:'007#转入卡卡号#转出卡卡号#转出卡密码',
                tips:'',
                example:'只有开户时输入的身份证号码相同的投注卡之间可互相转值。',
                },
              
         ]
    },
    // {
    //     name: '开户指令',
    //     title: '开户指令',
    //     icon: openAccountIcon,
    //     tip: '快捷开户，点这里',
    //     orders: [
    //             {
    //             name:'开户',
    //             order:'100#身份证#身份证号后六位',
    //             tips:'',
    //             example:'上海地区手机号码发送短信:100#身份证号#身份证号后六位到106388106388即可开通上海福彩电话投注。',
    //             },
    //             // {
    //             // name:'解除绑定',
    //             // order:'003#投注卡号或已开通投注功能的银行卡号#投注密码',
    //             // tips:'',
    //             // example:'',
    //             // },
    //             // {
    //             // name:'投注卡转值',
    //             // order:'007#转入卡卡号#转出卡卡号#转出卡密码',
    //             // tips:'',
    //             // example:'只有开户时输入的身份证号码相同的投注卡之间可互相转值。',
    //             // },
              
    //      ]
    // },
]

export const storeIdAuthor = [
    {
        storeId: '81666', // 8166电子卡
        hiddenEvents: [],
    },
    {
        storeId: '624', // 建行
        hiddenEvents: [],
    },
    {
        storeId: '613', // 农行
        hiddenEvents: ['recharge', 'rechargeQRCode', 'bankCards', 'drawback'],
    },
    {
        storeId: '611', // 交行
        hiddenEvents: ['recharge', 'rechargeQRCode', 'bankCards', 'drawback'],
    },
    {
        storeId: ['619', '614', '612', '625'], // 工行
        hiddenEvents: ['bankCards', 'drawback'],
    },
]

export const fundKinds = [
        {id: 'IMPREST', text: '充值', bgColor: 'fb941b', img: require('../assets/imgs/account_icon/recharge.png')},
        {id: 'RECOVERY', text: '过期红包', bgColor: 'bdbdbd', img: require('../assets/imgs/account_icon/expired_red_envelope.png')},
        {id: 'RETREAT', text: '退款', bgColor: '58b21d', img: require('../assets/imgs/account_icon/refund.png')},
        {id: 'AGENCYBUY', text: '普通投注（扣款）',bgColor: 'e80c16', img: require('../assets/imgs/account_icon/ordinary_betting.png')},
        {id: 'AUTOBUY', text: '自动多期（扣款）',bgColor: 'e80c16', img: require('../assets/imgs/account_icon/multi_period.png')},
        {id: 'AUTOBUY306', text: '多期投注（扣款）',bgColor: 'e80c16', img: require('../assets/imgs/account_icon/multi_period.png')},
        {id: 'DISPATCH', text: '返奖',bgColor: 'ff7814', img: require('../assets/imgs/account_icon/reward_return.png')},
        {id: 'DRAWING', text: '提现',bgColor: '2b74e3', img: require('../assets/imgs/account_icon/withdrawal.png')},
        {id: 'DRAWFAILURE', text: '提现失败退款',bgColor: '58b21d', img: require('../assets/imgs/account_icon/refund.png')},
        {id: 'MANUAREPARATIONS', text: '人工赔款',bgColor: '503ed8', img: require('../assets/imgs/account_icon/manual_compensation.png')},
        {id: 'POINTEXCHANGE', text: '积分兑换',bgColor: '9552db', img: require('../assets/imgs/account_icon/redemption_of_points.png')},
        {id: 'PRESENT', text: '活动赠金',bgColor: 'ffbe0d', img: require('../assets/imgs/account_icon/event_gift.png')},
        {id: 'BETCARDTRANS', text: '投注卡转账',bgColor: 'ba8127', img: require('../assets/imgs/account_icon/transfer_by_betting_card.png')},
        {id: 'CORRACC', text: '平账',bgColor: 'fbae1b', img: require('../assets/imgs/account_icon/balancing_account.png')},
        {id: 'MANUACHARG', text: '人工冲正退款',bgColor: '58b21d', img: require('../assets/imgs/account_icon/refund.png')},
        {id: 'OVERDUE', text: '赠金失效',bgColor: 'bdbdbd', img: require('../assets/imgs/account_icon/gift_invalid.png')},
        {id: 'REDCOUPON', text: '赠送红包',bgColor: 'ec4a25', img: require('../assets/imgs/account_icon/expired_red_envelope.png')},
        {id: 'SENDLOTTERY', text: '送彩票用户资金',bgColor: 'fbae1b', img: require('../assets/imgs/account_icon/sending_funds.png')},
        {id: 'SENDLOTTERYDEDUCT', text: '领彩票扣款',bgColor: 'e80c16', img: require('../assets/imgs/account_icon/deduction.png')},
        {id: 'SENDLOTTERYREFUND', text: '送彩票退款',bgColor: '58b21d', img: require('../assets/imgs/account_icon/refund.png')},
        {id: 'LOCALTRANSFER', text: '本账户资金互转',bgColor: '22a6df', img: require('../assets/imgs/account_icon/mutual_transfer_of_funds.png')},
    ]

export const redFundKinds = ['RETREAT', 'DRAWFAILURE', 'MANUAREPARATIONS', 'MANUACHARG', 'SENDLOTTERYREFUND']


export * from './bankMultiPeriodIntro.js'
export * from './phoneProcess.js'
export * from './bankInfo.js'
export * from './bonusInfo.js'
export * from './multiPeriodRules'
export * from './trendData'
export * from './fundDetail'
