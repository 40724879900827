
// 银行信息
export const bankInfo = [
    // {label: '电话投注卡', img: require('../assets/imgs/bank_dh_icon.png'), color: 'dh', colorValue: '#ff5317'},
    {label: '广发银行', storeId: '616', icon: require('../assets/imgs/bank/logo_gzfzyh.png'), img: require('../assets/imgs/bank/bank_guangfa.png'), color: 'gf', colorValue: '#e30121'},
    {label: '工商银行', storeId: '619', icon: require('../assets/imgs/bank/logo_gongshang.png'), img: require('../assets/imgs/bank/bank_gs_icon.png'), color: 'gs', colorValue: '#f5000d'},
    {label: '交通银行', storeId: '611', icon: require('../assets/imgs/bank/logo_jiaotong.png'), img: require('../assets/imgs/bank/bank_jt_icon.png'), color: 'jt', colorValue: '#063970'}, 
    {label: '招商银行', storeId: '614', icon: require('../assets/imgs/bank/logo_zhaoshang.png'), img: require('../assets/imgs/bank/bank_zs_icon.png'), color: 'zs', colorValue: '#ca0915'},
    {label: '农业银行', storeId: '613', icon: require('../assets/imgs/bank/logo_nonghang.png'), img: require('../assets/imgs/bank/bank_ny_icon.png'), color: 'ny', colorValue: '#14896e'},  
    {label: '浦东银行', storeId: '625', icon: require('../assets/imgs/bank/logo_pudongdazhan.png'), img: require('../assets/imgs/bank/bank_pudong.png'), color: 'pd', colorValue: '#0b3774'},
    {label: '建设银行', storeId: '624', icon: require('../assets/imgs/bank/logo_jianhang.png'), img: require('../assets/imgs/bank/bank_BBC.png'), color: 'js', colorValue: '#0061b5'},  
    
]

// 各银行投注流程
// 0: 标题 [0, ''], 
// 1: 黑色文字, [1, ''],
// 2: 有颜色的文字, [2, ''],
// 3: 有背景色颜色的文字 [3, ''],
export const bankLotteryProcess = [
    // 广发银行
    [
        [
            [0, '开通福彩投注账户：'],[1, '上海广发银行用户请点击广发手机银行APP首页，进入“城市服务”一“本地生活”一“上海福彩”，'], [1, '备注:'],[2, '（确保银行卡资金充足）'],[1, '即可开通福彩账户。'],],
        [
            [0, '投注、服务功能:'],
           [1, '您可以选择：1、电话语音投注'],[2, '(请拨打021-962333)'], [1, '。'],[1, '2、仅限上海地区手机号码'],[2, '（移动、联通、电信）'], [1, '编辑短信指令发送到'],[2, '106388106388'],[1, '或点击'],[3, '短信辅助选号'],[1, '进行投注。'],
        ], 
        
        [
            [0, '返奖：'], 
            [1, '用户中奖后，小奖奖金(小于或等于1万元)在'],[2, '2个工作日之内'],[1, '自动返奖到银行卡，单注奖金1万元以上的中奖用户，可凭有效证件'],[2, '(身份证)'],[1, '至四川中路321号上海福彩兑奖处领奖。'],
        ]
     ],
      // 工商银行
      [
        [
            [0, '开通福彩投注账户：'],
            [1, '请登录上海工商银行网银：'],[2, 'http://www.icbc.com.cn/icbc/'],[1, '进入'],[2, '“个人网上银行”一“登录个人网上银行”一“登录（输入银行卡号、密码)”一“分行特色”一“福利彩票”'],[1,'即可开通福彩账户。'],
        ],
        [
            [0, '充值:'], 
            [1, '请登录上海工商银行网银：'],
            [2, 'http://www.icbc.com.cn/icbc/进入“E缴费”一“更多缴费”一“其他缴费”一“其他”一“上海福利彩票”。'],
            [1, '1、工行银行卡已开通上海福利彩票电话投注业务；2、请使用银行卡号登录工行网银或App，进入“分行特色”一“福利彩票”进行如下操作：充值、修改中奖通知电话、修改返奖银行卡号等；3、为方便投注，建议您设置自动充值功能，确保投注账号资金充足；4、'], [2, '充值金额'],[1, '不可提现。'],
       ],
       [
            [0, '投注、服务功能:'],
             [1, '您可以选择：1、电话语音投注'],[2, '(请拨打021-962333)'], [1, '。'],[1, '2、仅限上海地区手机号码'],[2, '（移动、联通、电信）'], [1, '编辑短信指令发送到'],[2, '106388106388'],[1, '或点击'],[3, '短信辅助选号'],[1, '进行投注。'],
       ],
       [
            [0, '返奖：'], 
            [1, '用户中奖后，小奖奖金(小于或等于1万元)在'],[2, '2个工作日之内'],[1, '自动返奖到银行卡，单注奖金1万元以上的中奖用户，可凭有效证件'],[2, '(身份证)'],[1, '至四川中路321号上海福彩兑奖处领奖。'],
       ],
     ],
       //  交通银行
    [
        [
            [0, '开通福彩投注账户：'],
            [1, '上海交通银行暂不支持新用户开户。'],
        ],
        [
            [0, '投注、服务功能:'],
           [1, '您可以选择：1、电话语音投注'],[2, '(请拨打021-962333)'], [1, '。'],[1, '2、仅限上海地区手机号码'],[2, '（移动、联通、电信）'], [1, '编辑短信指令发送到'],[2, '106388106388'],[1, '或点击'],[3, '短信辅助选号'],[1, '进行投注。'],
        ],
        [
            [0, '返奖：'], 
            [1, '用户中奖后，小奖奖金(小于或等于1万元)在'],[2, '2个工作日之内'],[1, '自动返奖到银行卡，单注奖金1万元以上的中奖用户，可凭有效证件'],[2, '(身份证)'],[1, '至四川中路321号上海福彩兑奖处领奖。'],
        ],
    ],
      // 招商
      [
        [
            [0, '开通福彩投注账户：'],
            [1, '上海招商银行用户请拨打'],[2, '95555按语音菜单'],[1,'的提示操作，进入福利彩票语音菜单进行福彩开户流程。'],
        ],  
        [
            [0, '充值：'],
            [1, '1、请按照'],[2, '语音充值'],[1, '菜单操作,转入福彩投注资金。'], [1,'备注:'],[2, '充值金额'],[1,'不可提现。'],
        ],
        [
            [0, '投注、服务功能:'],
           [1, '您可以选择：1、电话语音投注'],[2, '(请拨打021-962333)'], [1, '。'],[1, '2、仅限上海地区手机号码'],[2, '（移动、联通、电信）'], [1, '编辑短信指令发送到'],[2, '106388106388'],[1, '或点击'],[3, '短信辅助选号'],[1, '进行投注。'],
        ],
        [
            [0, '返奖：'], 
            [1, '用户中奖后，小奖奖金(小于或等于1万元)在'],[2, '2个工作日之内'],[1, '自动返奖到银行卡，单注奖金1万元以上的中奖用户，可凭有效证件'],[2, '(身份证)'],[1, '至四川中路321号上海福彩兑奖处领奖。'],
        ] 
     ],
    // 农业银行
     [
       [
            [0, '开通福彩投注账户：'],
            [1, '请前往上海农业银行营业厅'],[2, '多媒体自助终端机一“开通福彩”一“修改预付保证金”'],[1, '（请确保银行卡资金充足）完成后，即可开通福彩投注账户。'],
       ],
       [
            [0, '投注、服务功能:'],
            [1, '您可以选择：1、电话语音投注'],[2, '(请拨打021-962333)'], [1, '。'],[1, '2、仅限上海地区手机号码'],[2, '（移动、联通、电信）'], [1, '编辑短信指令发送到'],[2, '106388106388'],[1, '或点击'],[3, '短信辅助选号'],[1, '进行投注。'],
       ],
       [
            [0, '返奖：'], 
            [1, '用户中奖后，小奖奖金(小于或等于1万元)在'],[2, '2个工作日之内'],[1, '自动返奖到银行卡，单注奖金1万元以上的中奖用户，可凭有效证件'],[2, '(身份证)'],[1, '至四川中路321号上海福彩兑奖处领奖。'],
       ]
     ],

    // 上海浦东发展银行
    [
        [
            [0, '充值：'],
            [1, '请登录www.81666.net进行充值。备注：'],[2, '充值金额'],[1, '不可提现。'],
        ],
        [
            [0, '投注、服务功能:'],
            [1, '您可以选择：1、电话语音投注'],[2, '(请拨打021-962333)'], [1, '。'],[1, '2、仅限上海地区手机号码'],[2, '（移动、联通、电信）'], [1, '编辑短信指令发送到'],[2, '106388106388'],[1, '或点击'],[3, '短信辅助选号'],[1, '进行投注。'],
        ],
        [
            [0, '返奖：'], 
            [1, '用户中奖后，小奖奖金(小于或等于1万元)在'],[2, '2个工作日之内'],[1, '自动返奖到银行卡，单注奖金1万元以上的中奖用户，可凭有效证件'],[2, '(身份证)'],[1, '至四川中路321号上海福彩兑奖处领奖。'],
        ],
    ],
     // 建设银行
     [
        [
            [0, '充值：'],
            [1, '1、请登录www.81666.net进行充值。备注：'],[2, '充值金额'],[1, '不可提现。'],
        ],
        [
            [0, '投注、服务功能:'], 
            [1, '您可以选择：1、电话语音投注'],[2, '(请拨打021-962333)'], [1, '。'],[1, '2、仅限上海地区手机号码'],[2, '（移动、联通、电信）'], [1, '编辑短信指令发送到'],[2, '106388106388'],[1, '或点击'],[3, '短信辅助选号'],[1, '进行投注。'],
        ],
        [
            [0, '返奖：'], 
            [1, '用户中奖后，小奖奖金自动返还至用户投注账户,返奖奖金可以提现,单注奖金1万元以上的中奖用户可凭有效证件(身份证)到上海福彩兑奖处领奖。'],
            // [2, '2个工作日之内'],[1, '自动返奖到银行卡，单注奖金1万元以上的中奖用户，可凭有效证件'],[2, '(身份证)'],[1, '至四川中路321号上海福彩兑奖处领奖。'],
        ],
     ], 
]